import Request from "@/lib/apollo/request";

declare module 'vue/types/vue' {
    interface Vue {
        $errorState(resultObj: any, field: string): any;
        $errorStateCVL(resultObj: any, field: string): any;
        $getAddress(zip: string, number: string, country_id: string): any;
    }
}

export default class FormPlugin {
    public static install(Vue: any, options: any) {
        Vue.prototype.$errorState = function (resultObj: any, field: string) {
            const empty = {
                'error': false,
                'error-messages': [],
            };

            if (typeof resultObj.msg === 'undefined') {
                return empty;
            }

            const fields = field.split('.');

            let output;
            if (fields.length === 1) {
                output = resultObj.msg[fields[0]];
            } else if (fields.length > 1) {
                let msgObj = resultObj.msg[fields[0]];
                fields.reduce((prev, current) => {
                    msgObj = msgObj[current];
                    return current;
                });
                output = msgObj;
            } else {
                return empty;
            }

            if (!output) {
                return empty;
            }

            return {
                'error': output.length > 0,
                'error-messages': output,
            };
        }
        Vue.prototype.$errorStateCVL = function (resultObj: any, field: string) {
            const output: any = {
                'error': false,
                'error-messages': [],
            };

            if (resultObj['@type'] === 'ConstraintViolationList') {
                if (typeof resultObj.violations === 'object') {
                    for (const key in resultObj.violations) {
                        if (resultObj.violations[key].propertyPath === field) {
                            output["error"] = true;
                            output.feedback.push(resultObj.violations[key].message);
                        }
                    }
                }
            }

            return output;
        }
        Vue.prototype.$getAddress = function (zip: string, number: string, country_id: string) {
            if (!zip.length || !number.length || !country_id.length) {
                return new Promise<void>((resolve, reject) => { reject() });
            }

            const formData = new FormData();
            formData.append('zipcode', zip);
            formData.append('number', number);
            formData.append('country_id', country_id);

            return Request.make('/zipcode-api', Request.POST, formData);
        };
    }
}
