<template>
    <div>
        <v-card class="full-width">
            <data-table
                    :no-data-text="$dataText(loading)"
                    :loading="loading"
                    :headers="headers"
                    v-model="queried"
                    :items="items"
                    item-key="id"
                    :sort-by="[]"
                    v-bind:options.sync="pagination"
                    show-select
            >
                <template v-slot:item.createdAt="{ item }">
                    <td class="text-left">{{ item.date | moment(dateformat) }}</td>
                </template>
                <template v-slot:item.number="{ item }">
                    <td class="text-left">{{ item.number }}</td>
                </template>
                <template v-slot:item.relation.name="{ item }">
                    <td class="text-left">{{ item.relation.name }}</td>
                </template>
                <template v-slot:item.relation.invoiceEmail="{ item }">
                    <td class="text-left">{{ item.relation.invoiceEmail }}</td>
                </template>
                <template v-slot:item.errorWhileSending="{ item }">
                    <td class="text-left">
                        <template v-if="item.errorWhileSending">
                            <v-chip color="red" dark @click="enableJobInfoModal(item.lastJobID)">
                                <v-icon>fal fa-triangle-exclamation</v-icon>
                            </v-chip>
                        </template>
                    </td>
                </template>
                <template v-slot:item.actions="{ item }">
                    <td class="text-right" style="width:0">
                        <v-btn color="primary" @click.stop="openPdf(item.id)">
                            <v-icon action white>far fa-file-pdf</v-icon>
                        </v-btn>
                    </td>
                </template>
            </data-table>
            <v-card-actions>
                <template v-if="remaining > 0">
                    <v-btn text color="primary" :disabled="sending">
                        {{ $t('{0} remaining').toString().replace('{0}', String(remaining)) }}
                    </v-btn>
                </template>
                <template v-else-if="queried.length > 0">
                    <v-btn text color="primary" @click="sendSelectedInvoices()" :disabled="sending">
                        {{ $t('Send {0} selected invoices').toString().replace('{0}', String(queried.length)) }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn text color="primary" @click="enableSendConfirmModal()" :disabled="sending || !items.length">
                        {{ $t('Send all invoices') }}
                    </v-btn>
                </template>
                <v-spacer />
                <v-btn @click="clear" text color="red" v-if="!sending" >
                    {{ $t('Clear') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <job-info :id.sync="jobInfoModal.id" />
        <v-dialog v-model="sendConfirmModal.enabled" max-width="500">
            <v-card>
                <v-card-title>
                    {{ $t('Confirm') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('Send all {0} invoices?').toString().replace('{0}', String(items.length)) }}
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="primary" @click="disableSendConfirmModal()">
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="sendInvoices()" :disabled="sending">
                        {{ $t('Continue') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts" type="typescript">
    import JobInfo from "@/components/job-info.vue";
    import { Request } from '@/lib/apollo';
    import Vue from "vue";
    import {Component, Watch} from "vue-property-decorator";
    import Config from "@/config";

    interface InvoiceObject {
        id: string;
        relation: {
            name: string,
            invoiceEmail: string,
        };
        number: number;
        date: string;
        queuedForSending: any;
        sentAt: any;
        errorWhileSending: boolean;
    }

    @Component({
        components: {JobInfo}
    })
    export default class PrintQueueList extends Vue {
        loading: boolean = true;
        headers = [
            { text: '', value: 'checkbox' },
            { text: 'Date', value: 'createdAt' },
            { text: 'Invoice Number', value: 'number' },
            { text: 'Customer', value: 'relation.name' },
            { text: 'Invoice e-mail', value: 'relation.invoiceEmail' },
            { text: '', value: 'errorWhileSending' },
            { text: '', value: 'actions' }
        ];
        items: InvoiceObject[] = [];
        queried = [];
        dateformat = Config.dateformat;
        sending: boolean = false;
        remaining = 0;

        pagination: any = {
            sortDesc: [false],
            sortBy: ['createdAt'],
            page: 1,
            itemsPerPage: 10,
        };

        progressInterval: number = 0;

        jobInfoModal: any = {
            id: 0,
        };

        sendConfirmModal: any = {
            enabled: false,
        };

        get selected() {
            return Object.keys(this.queried).length !== 0
        }

        created() {
            this.getInvoices();
        }

        getInvoices() {
            this.loading = true;
            return Request.make('/api/printqueue/ready', Request.GET).then((res) => {
               this.items = res.invoices;
               this.sending = res.running;
               this.remaining = res.runningCount;
               this.queried = [];
               this.loading = false;
            });
        }

        getRunning() {
            return Request.make('/api/printqueue/running', Request.GET).then((res) => {
                this.sending = res.running;
                this.remaining = res.runningCount;
            });
        }

        clear() {
            if (confirm('Weet u het zeker?')) {
                Request.make('/api/printqueue', Request.DELETE).then(() => {
                    this.getInvoices();
                    this.$flashMessage('Printqueue geleegd!');
                });
            }
        }

        openPdf(id: string) {
            let link = Config.api.host + '/api/invoices/' + encodeURIComponent(id) + '/render';
            window.open(link, '_blank');
        }

        sendInvoices(ids: string[] = []) {
            this.sending = true;
            this.disableSendConfirmModal();

            let data: any = {};

            if (ids.length > 0) {
                data = {
                    ids: ids
                };
            }

            Request.make('/api/printqueue/send', Request.POST, data).then((res: any) => {
                this.$flashMessage('Facturen zullen verstuurd gaan worden', 'success');
                this.getRunning();
            }).catch((reason: any) => {
                this.$flashMessage('Er is een fout opgetreden.', 'error');
                this.getRunning();
                console.error(reason);
            });
        }

        sendSelectedInvoices() {
            const ids = this.queried.map((obj) => obj.id);
            if (!ids.length) {
                this.$flashMessage('Geen facturen geselecteerd', 'error');
                return;
            }

            this.sendInvoices(ids);
        }

        enableJobInfoModal(jobID: number) {
            this.jobInfoModal.id = jobID;
        }

        enableSendConfirmModal() {
            this.sendConfirmModal.enabled = true;
        }

        disableSendConfirmModal() {
            this.sendConfirmModal.enabled = false;
        }

        @Watch('sending')
        onSendingChange(sending: boolean) {
            if (sending === true) {
                this.progressInterval = window.setInterval(this.getRunning, 2000);
            } else {
                window.clearInterval(this.progressInterval);
                this.getInvoices();
            }
        }
    }
</script>
